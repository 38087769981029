
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import { camelizeKeys } from "humps"
import { useCameraStore } from "@/stores/camera"

export default {
  components: {
    BimCompareContainer,
  },
  layout: "widget",
  async asyncData({ query, redirect }) {
    const cameraStore = useCameraStore()
    const queryObj = camelizeKeys(query)

    await cameraStore.selectCamera(queryObj.camera)
    if (!cameraStore.selectedCamera) {
      redirect("/v2/users/signin")

      return
    }

    return {
      camera: cameraStore.selectedCamera,
      darkMode: query.dark_mode === "true",
      embedded: query.embedded === "true",
    }
  },
}
